<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>品牌签约信息查看</h3>
    </div>

    <div class="x-main">
      <el-button
        icon="el-icon-arrow-left"
        type="primary"
        size="small"
        @click="$router.back()"
        >返回</el-button
      >
      <el-button
        icon="el-icon-refresh"
        type="primary"
        plain
        size="small"
        @click="doRefresh"
        >刷新</el-button
      >
      <!-- 筛选条件 -->
      <div class="x-q-panel" style="margin-top: 10px; margin-bottom: 5px">
        <el-row
          ><el-col :span="8">
            <span>品牌方：{{ id }}</span></el-col
          ><el-col :span="8">
            <span>签约主体：{{ companyName }}</span></el-col
          ><el-col :span="8">
            <span>签约时间：{{ created_at }}</span></el-col
          ></el-row
        >
        <div class="x-q-msg">
          <span>区块链地址(发行)：</span>{{ brandBlockchain }}
        </div>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption"></div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="NFTID" label="藏品ID" width="120">
            </el-table-column>
            <el-table-column
              prop="NFTName"
              label="藏品名称"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="collectionName"
              label="藏品集名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="totalCount"
              label="发行数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="onlineCount"
              label="上架数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="availableCount"
              label="可上架数量"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="availableCount"
              label="实际购买数量"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="NFTTime" label="发行时间"> </el-table-column>
            <el-table-column
              prop="brandBlockchain"
              label="上架状态"
              align="center"
            >
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.status == 1"
                  >未上架</span
                >
                <span class="font-success" v-else-if="scope.row.status == 2"
                  >待审核</span
                >

                <span class="font-danger" v-else-if="scope.row.status == 3"
                  >待上架</span
                >
                <span class="font-success" v-else-if="scope.row.status == 4"
                  >已上架</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goDetail(scope.row.id)"
                    >查看NFT详情</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BrandAssetQuery } from "@/api/module/brand";

export default {
  name: "BrandAssetIndex",
  props: ["id", "companyName", "created_at", "created_at", "brandBlockchain"],
  data() {
    return {
      showLoading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    goDetail() {
      this.$message({
        type: "info",
        message: "功能正在开发中...",
        duration: 1300,
      });
    },

    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      return query;
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      BrandAssetQuery(this.id, query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },
    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>
<style lang="less">
.x-q-panel {
  .x-q-msg {
    margin-top: 20px;
    font-size: 13px;
  }
  span {
    font-size: 20px;
  }
}
</style>


